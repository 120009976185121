<template>
  <div class="ma-list" v-loading="tableLoading">
    <div class="content">
      <el-table
        class="thead-light"
        :data="tbody"
        style="width: 100%"
        stripe
      >
        <el-table-column
          v-for="th in thead"
          :key="th.prop"
          :prop="th.prop"
          :label="th.label"
          :width="th.width||''"
          :sortable="th.sort"
          :fixed="th.fixed"
          :min-width="th.min_width"
        >
          <template slot-scope="{row}">
            <!-- 操作 -->
            <template v-if="th.prop==='operate'">
              <el-button type="text" size="mini" @click="goMAConfig(row.index)">配置</el-button>
            </template>
            <!-- 其他 -->
            <span v-else>{{row[th.prop]}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { maIntroList } from "../api/ma-intro";
export default {
  data() {
    return {
      tableLoading: false, //加载中
      thead: [
        { label: "名称", prop: "name", width: 320 },
        { label: "描述", prop: "describe", width: 300 },
        { label: "最后修改时间", prop: "update_time", min_width: 250 },
        { label: "操作", prop: "operate", width: 100, fixed: "right" },
      ],
      tbody: [],
    };
  },
  methods: {
    //获取列表数据
    getMAIntroList() {
      this.tableLoading = true;
      maIntroList()
        .then((res) => {
          const { data } = res;
          this.tbody = data;
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    //配置
    goMAConfig(data) {
      this.$router.push({ name: "MAConfig", params: {name: data}});
    },
  },
  created() {
    //获取列表数据
    this.getMAIntroList();
  },
};
</script>
<style lang="scss" scoped>
.ma-list {
}
</style>
